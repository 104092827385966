<template>
  <Head>
    <title>Sign In</title>
  </Head>
  <Guest>
    <AuthCard>
      <template #logo>
        <a href="/">
          <ApplicationLogo class="block h-11 w-auto fill-current text-gray-500 mb-16" />
        </a>
      </template>

      <template #heading>
        <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
          Sign in to your account
        </h2>
      </template>

      <InsightsAlert
        v-if="flashMessage"
        type="warning"
        class="mb-4"
      >
        {{ flashMessage }}
      </InsightsAlert>

      <AuthSessionStatus class="mb-4" />

      <form
        method="POST"
        @submit.prevent="submit"
      >
        <InsightsFormGroup
          label="Email address"
          name="email"
        >
          <InsightsInput
            id="email"
            v-model="form.email"
            name="email"
            placeholder="you@example.com"
            type="email"
          />
        </InsightsFormGroup>

        <InsightsFormGroup
          label="Password"
          name="password"
        >
          <InsightsInput
            id="password"
            v-model="form.password"
            name="password"
            placeholder="Password"
            type="password"
          />
        </InsightsFormGroup>

        <div class="flex justify-between mb-5">
          <InsightsCheckbox
            v-model:checked="form.remember_me"
            name="remember_me"
            label="Remember me"
          />

          <a
            class="text-sm text-primary-600 font-medium"
            :href="route('password.request')"
          > Forgot your password? </a>
        </div>

        <div class="flex flex-col text-center">
          <InsightsButton class="flex-grow text-center justify-center mb-5">
            Log in
          </InsightsButton>
        </div>
      </form>
    </AuthCard>
  </Guest>
</template>

<script setup lang="ts">
import { Head, useForm, usePage } from '@inertiajs/vue3';
import Guest from '@/Layouts/Guest.vue';
import AuthCard from '@/Components/Auth/AuthCard.vue';
import AuthSessionStatus from '@/Components/Auth/AuthSessionStatus.vue';
import ApplicationLogo from '@/Components/ApplicationLogo.vue';
import InsightsFormGroup from '@/Components/Forms/InsightsFormGroup.vue';
import InsightsInput from '@/Components/Forms/InsightsInput.vue';
import InsightsCheckbox from '@/Components/Forms/InsightsCheckbox.vue';
import InsightsButton from '@/Components/Forms/InsightsButton.vue';
import InsightsAlert from '@/Components/InsightsAlert.vue';
import { computed } from 'vue';

const form = useForm({
  email: null,
  password: null,
  remember_me: false,
  _token: usePage().props.csrf_token,
});

const flashMessage = computed(() => usePage().props.flash.message);

const submit = () => form.post(route('login'));
</script>
